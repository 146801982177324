import axios from "axios";
import {useDropzone} from "react-dropzone";
import {useNavigate} from "react-router-dom";




function MainWebSite() {
    let body = document.querySelector('body');
    body.style.overflow = 'hidden';
    body.style.margin = '0px';
    body.style.height = '100vh';
    //margin:0px;padding:0px;overflow:hidden;height:100vh;


    return (
        <iframe src="https://www.realeyesit.com/technology/attention-ai-demo/" frameBorder="0" allow="clipboard-write *"
                style={{
                    overflow:'hidden',
                    height:'100%',
                    width:'100%'}} height="100%" width="100%">
        </iframe>
    );
}

export default MainWebSite;
import React from "react";
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom'
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import FileUpload from "./components/UploadFiles";
import RecommendationsResults from "./components/Recommendations";
import MainWebSite from "./components/MainWebSite";

function App({loggingService}) {
  return (
      <Router>
              <Routes>
                  <Route path="/" element={<MainWebSite />}/>
                  {/*<Route path="/" element={<FileUpload />}/>*/}
                  <Route path="/recommendations/:id" element={<RecommendationsResults loggingService={loggingService}/>}/>
                  <Route path="/upload/" element={<FileUpload loggingService={loggingService} />}/>
                  <Route path="/upload/recommendations/:id" element={<RecommendationsResults loggingService={loggingService}/>}/>
              </Routes>
      </Router>
  );
}

export default App;
import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";

import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';


const UPLOAD_ENDPOINT = (process.env.REACT_APP_PREVIEW_API_URL || "https://preview-ai-api.realeyesit.com/v1/") + "files";
const DEMO_API_KEY = process.env.REACT_APP_PREVIEW_API_KEY || "demo-preview-api-key-2024-05-17-limited";

axios.defaults.headers.common['x-api-key'] = DEMO_API_KEY

const ALLOWED_VIDEO_FILE_TYPES =  [".mp4", ".mov", ".avi", ".mpeg", ".mpg", ".m4v"] 
const ALLOWED_IMAGE_FILE_TYPES = [".gif", ".png", ".jpg", ".jpeg"]

const ext_to_mime_map = {
    'mp4':"video/mp4",
    'mov':"video/quicktime",
    'avi':"video/x-msvideo",
    'm4v':"video/x-m4v",
    'mpeg':"video/mpeg",
    'gif':"image/gif",
    'png':"image/png",
    'jpg':"image/jpeg",
    'jpeg':"image/jpeg",
}

const MAX_FILE_SIZE = 20

function FileUpload({loggingService}) {

    function extAndSizeValidator(file) {
        if (file.size === 0) {
            return {
                code: "file-empty",
                message: "File is empty. Please choose a non-empty file."
            };
        }

        if (file.size > MAX_FILE_SIZE*1024*1024) { // MAX_FILE_SIZE MB in bytes
            return {
                code: "file-size-exceeds",
                message: `File size exceeds ${MAX_FILE_SIZE} MB. Please choose a smaller file.`
            };
        }

        return null
    }

    const onDrop = useCallback(async acceptedFiles => {
        if (acceptedFiles.length === 0) {
            return;
        }

        let file = acceptedFiles[0];
        setIsLoading(true);

        // const formData = new FormData();
        // formData.append("file", file);

        let ext = file.name.split('.').pop().toLowerCase();
        let mime = ext_to_mime_map[ext] || 'video/mp4'

        const startTime = performance.now();
        loggingService.log(`File upload started: ${file.name} (${file.size} bytes, ${file.type})`);
        try {
            const resp = await axios.put(UPLOAD_ENDPOINT, file, {
                headers: {
                    "content-type": mime,
                    "File-Name": file.name.replace(/[^a-zA-Z0-9.]/g, '_'),
                },
            });
            setIsSuccess(true);
            setIsLoading(false);
            navigate('/upload/recommendations/'+resp.data['unique_file_id'], { replace: true });
            loggingService.log(`File upload successful: ${file.name} - ${(performance.now() - startTime)} ms`);
        }
        catch (error) {
            setIsLoading(false);
            setIsSuccess(false);
            setError("The Demo App cannot process this file. Please try an alternative.");
            loggingService.log(`File upload failed: ${file.name} - ${error}`);
        }
    }, [])

    function clearError() {
        if (error)
            setError(null);
    }

    const {
        fileRejections,
        getRootProps,
        getInputProps,
        isDragActive} = useDropzone({onDrop,
        onDragEnter: clearError,
        onFileDialogOpen: clearError,
        maxFiles: 1,
        validator: extAndSizeValidator,
            accept: {
                'video/*': ALLOWED_VIDEO_FILE_TYPES,
                'image/*': ALLOWED_IMAGE_FILE_TYPES
        }

    })

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <div key={file.name}>
            {errors.map(e => (
                <div key={e.code}>{e.message}</div>
            ))}
        </div>
        // <li key={file.path}>
        //     {file.path} - {file.size} bytes
        //     <ul>
        //         {errors.map(e => (
        //             <li key={e.code}>{e.message}</li>
        //         ))}
        //     </ul>
        // </li>
    ));

    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(null);
    // const [progress, setProgress] = useState(0);

    let navigate = useNavigate();



    return (
        <div className="container" style={{ width: "800px" }}>
            <div className='upload-form'  style={{
                display: isLoading ? 'none' : 'block'
            }}>
            <div {...getRootProps()} className='upload-area'>
                <input {...getInputProps()} />
                <div className='upload-icon'></div>
                <p className='caption'>Upload Video or Image File</p>
                <p>mp4, mov, avi, mpeg, mpg, m4v, gif, png, jpg, jpeg -  {MAX_FILE_SIZE}MB or less</p>
                <p style={{fontWeight:'700', textDecoration: 'underline'}}>BROWSE FILES</p>
            </div>
                {fileRejections.length>0?
                    <aside className='rejected-files'>
                        <ul>{fileRejectionItems}</ul>
                    </aside>
                    :null}
                {error ? <div className="error">{error}</div> : null}
            </div>
            {/*{isLoading ? <div>*/}
            {/*    <h4>Uploading in progress</h4>*/}
            {/*    <progress value={null} />*/}
            {/*</div> : null}*/}

            <div className='uploading-progress-form'  style={{
                display: isLoading ? 'flex': 'none'
            }}>
                <div className='uploading-icon'>

                </div>
                <p className='caption'>Analyzing media</p>
                <p>(This usually takes a minute or so)</p>
            </div>
        </div>
    );
}

export default FileUpload;

import Row from "react-bootstrap/Row";

function  VideoPlayer(params) {
    let id = params.id;
    let info = params.info;
    let start = params.start;
    let end = params.end;
    let onTimeUpdateExt = params.onTimeUpdate;



    let url = '';
    if (info && info['url']){
        url = info['url'];
        if (start && end){
            url = url + '#t='+start+','+end;
        }
    }

    const onHover = (e) => {
        if (end && e.currentTarget.src) {
            try{
                e.currentTarget.play();
            }catch(e){
                console.log(e);
            }

        }
    };

    const onLeave = (e) => {
        if (end && e.currentTarget.src) {
            try{
                e.currentTarget.pause();
            }catch(e){
                console.log(e);
            }
        }
    };

    const onTimeUpdate = (e) => {
        if (end && e.currentTarget.currentTime > end){
            e.currentTarget.currentTime = start || 0 ;
        }
        if (onTimeUpdateExt){
            onTimeUpdateExt(e);
        }
    };

    return (
        <div>
            {info ? (
                <Row className='player-row'>
                    {params.no_title?'': <h2>{info.name}</h2>}
                    <video id={id}  onMouseEnter={onHover} onTimeUpdate={onTimeUpdate}
                            webkit-playsinline={true}
                            playsInline={true}
                            muted={end?true:false}
                            onMouseLeave={onLeave}
                            controls={params['no_controls']? false:true}
                            src={url} style={(params.max_w)?{maxWidth:params.max_w}:{}}>
                    </video>
                </Row>
                )
            : (<div>No video</div>)
            }
        </div>
    )

}

export default VideoPlayer;
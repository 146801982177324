import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import VideoPlayer from "./VideoPlayer";
import { FaPlus, FaMinus } from "react-icons/fa6";

function  RecommendationsRow(params) {
    let predictions = ((params || {'predictions':null}).predictions || {'data':null})['data'];
    let info = ((params || {'recomms':null}).recomms || {'data':null})['data'];
    let v_info = (params || {'v_info':null}).v_info;

    const toggleClass = (element) => {
        element.currentTarget.parentElement.classList.toggle('open');
        element.currentTarget.parentElement.classList.toggle('close');
    };

    let perSceneRow = predictions && predictions['scenes'] ? predictions['scenes'].map(function(s_i) {
        let recs = info? info['per_scene_recommendation'].filter((r) => r['scene_number'] === s_i['scene_number']):[];

        let noneText = info? "None":"Processing";
        const roundedAttentionPotential = Math.round(Number(s_i['attention_potential'].toFixed(1)));

        return <Row className={ 'result-row scenes '+ (recs.length>0 ? 'open':'close')}>
            <div className='collapse-row' onClick={toggleClass}>
                <div className='plus'><FaPlus/></div>
                <div className='minus'><FaMinus/></div>
            </div>
            <Col>
                <b>Scene {s_i['scene_number']}</b> / {(s_i['start']/1000.0).toFixed(2)}-{(s_i['end']/1000.0).toFixed(2)}
                <VideoPlayer info={v_info} start={s_i['start']/1000.0} end={s_i['end']/1000.0} no_title={true} no_controls={true}
                max_w = '250px'
                ></VideoPlayer>
            </Col>
            <Col>
                <h2>Attention Potential</h2>
                <span className='score'>{roundedAttentionPotential}</span>
            </Col>
            <Col xs={6}>
                <h2>Recommendation</h2>
                <Row className='recs'>{recs.length>0 ? <span>{recs[0]['recommendation']}</span>: <span>{noneText}</span>}</Row>
                <Row className='rec-exists'>{recs.length>0 ? <span>Yes</span>: <span>{noneText}</span>}</Row>
            </Col>
        </Row>;
    }) : null;

    return (
        <div className='results'>
            <Row>
                {
                    info ?
                    <Row className='result-row'>
                        <h1>Overall Performance</h1>
                        <Col>
                            <div className={'assigment assigment-'+(info['general_assessment']||predictions['general_assessment']||'').toLowerCase()}>
                                <span className='score'>{(info['general_assessment']||predictions['general_assessment']||'')}</span>
                            </div>
                        </Col>
                        <Col>
                            <div className='score-container'>
                                <h2>Attention Potential</h2>
                                {predictions?
                                    <span className='score'>{predictions['attention_potential'].toFixed(0)}</span>
                                :<span className='score'>-</span>
                                }
                            </div>
                        </Col>
                        <Col xs={6} s={6}>
                            <h2>Recommendation</h2>
                            <span>{info['general_recommendation']}</span>
                        </Col>
                    </Row>:
                    <Row className='result-row'>
                        <h1>Overall Performance</h1>
                        <Col>
                            <div className='assigment assigment-processing'>
                                <div className='icon'></div>
                            </div>
                        </Col>
                        <Col>
                            <div className='score-container'>
                                <h2>Attention Potential</h2>
                                {predictions?
                                    <span className='score'>{predictions['attention_potential'].toFixed(0)}</span>
                                :<span className='score'>-</span>
                                }
                            </div>
                        </Col>
                        <Col xs={6} s={6}>
                            <h2>Recommendation</h2>
                            <span>Processing</span>
                        </Col>
                    </Row>
                }
             {perSceneRow}
            </Row>
        </div>
    )
}

export default RecommendationsRow;

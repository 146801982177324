import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BsFillShareFill } from "react-icons/bs";
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import axios from "axios";
import VideoPlayer from "./VideoPlayer";
import RecommendationsRow from "./RecommendationsRow";
import Form from 'react-bootstrap/Form';
const API_ENDPOINT = process.env.REACT_APP_PREVIEW_API_URL || "https://preview-ai-api.realeyesit.com/v1/";
const DEMO_API_KEY = process.env.REACT_APP_PREVIEW_API_KEY || "demo-preview-api-key-2024-05-17-limited";



axios.defaults.headers.common['x-api-key'] = DEMO_API_KEY
axios.defaults.headers.common['content-type'] = "application/json"

const executeRequest = async (innerPromise, message = 'Server error') => {
    try {
        return await innerPromise;
    } catch (error) {
        return {
            'data': {
                'state':'failed', 'error': message, '$serverError': error
            }
        };
    }
}

const requestPredictions = async (id,file_type) => {
    let url = file_type === 'video' ? 'videos/predicted-reactions/':'images/predicted-reactions/';
    const resp = await executeRequest(axios.post(API_ENDPOINT+ url+ id, {}), `Server error while requesting predictions for ${id}` );
    return resp.data;
};

const getPredictions = async (id,file_type) => {
    let url = file_type === 'video' ? 'videos/predicted-reactions/':'images/predicted-reactions/';
    const resp = await executeRequest(axios.get(API_ENDPOINT+url+ id, {}), `Server error while getting predictions for ${id}` );
    return resp.data;
};

const requestRecommendations = async (id, mediaType) => {
    const resp = await executeRequest(axios.post(API_ENDPOINT+`${mediaType}s/recommendations/`+ id, {}), `Server error while requesting recommendations for ${id}` );
    return resp.data;
};

const getRecommendations = async (id, mediaType) => {
    const resp = await executeRequest(axios.get(API_ENDPOINT+`${mediaType}s/recommendations/`+ id, {}), `Server error while getting recommendations for ${id}` );
    return resp.data;
};

const requestHeatMap = async (id, mediaType) => {
    const resp =  await executeRequest(axios.post(API_ENDPOINT+`${mediaType}s/heatmap/`+ id, {}), `Server error while requesting heatmap for ${id}` );
    return resp.data;
};

const requestFogMap = async (id, mediaType) => {
    const resp = await executeRequest(axios.post(API_ENDPOINT+`${mediaType}s/heatmap/`+ id , {'overlay_type':2}), `Server error while requesting fogmap for ${id}` );
    return resp.data;
};

const getHeatMap = async (id, mediaType) => {
    const resp =  await executeRequest(axios.get(API_ENDPOINT+`${mediaType}s/heatmap/`+ id, {}), `Server error while getting heatmap for ${id}` );
    return resp.data;
};

const getFogMap = async (id, mediaType) => {
    const resp = await executeRequest(axios.get(API_ENDPOINT+`${mediaType}s/heatmap/`+ id + '?overlay_type=2', {}), `Server error while getting fogmap for ${id}` );
    return resp.data;
};

const getVideoInfo = async (id) => {
    const resp = await executeRequest(axios.get(API_ENDPOINT+'files/'+ id,{}), `Server error while getting video info for ${id}` );
    return resp.data;
};

function  RecommendationsResults({loggingService}) {
    let { id } = useParams();
    const [info, setInfo] = useState({});
    const [recomms, setRecomms] = useState({}); 
    const [heatmap, setHeatMap] = useState({});
    const [fogmap, setFogmap] = useState({});
    const [predictions, setPredictions] = useState({});
    let mainPlayer = document.querySelector('video#main');
    let heatmapPlaeyr = document.querySelector('video#heatmap');
    const [activeVideo, setActiveVideo] = useState('Original');
    const [recommendcationsTriggered, setRecommendcationsTriggered] = useState(false)
    const [errors, setErrors] = useState([]);
    const [isFogmapActive, setIsFogmapActive] = useState(false);
    const [showCopiedToClipboard, setShowCopiedToClipboard] = useState(false);

    const navigate = useNavigate();

    const syncPlayers = (e) => {
        if (!mainPlayer || !heatmapPlaeyr){
            return;
        }

        if (e.currentTarget === mainPlayer && activeVideo!=='Original' && heatmapPlaeyr.paused) {
            mainPlayer.currentTime = heatmapPlaeyr.currentTime;
            mainPlayer.pause();
        }

        if (e.currentTarget === heatmapPlaeyr && activeVideo!=='Original' && mainPlayer.paused && !heatmapPlaeyr.paused) {
            mainPlayer.currentTime = heatmapPlaeyr.currentTime;
            mainPlayer.play();
        }
    };

    const onShareClick = () => {
        const textToCopy = window.location.href;

        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard.writeText(textToCopy);
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
                
            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";
                
            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (error) {
                //NOP
            } finally {
                textArea.remove();
            }
        }
        setShowCopiedToClipboard(true);
    }


    useEffect(() => {        
        getVideoInfo(id).then((data)=> {
            setInfo(data.data);
        });

    }, []);

    useEffect(() => {
        const startTime = performance.now();
        if(info &&  info['file_type'] && !recommendcationsTriggered){
            let recommendations_check = () => {                
                getRecommendations(id, info['file_type']).then((data) => {
                    setRecomms(data);
                    if (data['state'] === 'not_requested') {
                        requestRecommendations(id, info['file_type']).then((data) => {
                            setTimeout(recommendations_check, 1000);
                        });
                    } else if (data['state'] !== 'failed' && data['state'] !== 'processed') {
                        setTimeout(recommendations_check, 5000);
                    } else if (data['state'] === 'failed') {
                        setErrors(prev => [...prev, data['error']]);
                        loggingService.log(`Recommendation check failed with ${id} - ${data['$serverError']}`);
                    }
                    else {
                        loggingService.log(`Recommendation check finished with ${id} - ${performance.now()-startTime} ms`);
                    }
                })
            }
    
            let heatmap_check = () => {
                getHeatMap(id,info['file_type']).then((data) => {
                    setHeatMap(data);
                    if (data['state'] === 'not_requested') {
                        requestHeatMap(id, info['file_type']).then((data) => {
                            setTimeout(heatmap_check, 1000);
                        });
                    } else if (data['state'] !== 'failed' && data['state'] !== 'processed') {
                        setTimeout(heatmap_check, 5000);
                    } else if (data['state'] === 'failed') {
                        setErrors(prev => [...prev, data['error']]);
                        loggingService.log(`Heatmap check failed with ${id} - ${data['$serverError']}`);
                    }
                    else{
                        loggingService.log(`Heatmap check finished with ${id} - ${performance.now()-startTime} ms`);
                    }
                })
            }

            let fogmap_check = () => {
                getFogMap(id,info['file_type']).then((data) => {
                    setFogmap(data);
                    if (data['state'] === 'not_requested') {
                        requestFogMap(id, info['file_type']).then((data) => {
                            setTimeout(fogmap_check, 1000);
                        });
                    } else if (data['state'] !== 'failed' && data['state'] !== 'processed') {
                        setTimeout(fogmap_check, 5000);
                    }
                     else if (data['state'] === 'failed') {
                        setErrors(prev => [...prev, data['error']]);
                    }
                })
            }
    
    
            let pred_check = (file_type)=>{
                getPredictions(id, file_type).then((data)=> {
                    setPredictions(data);
                    if (file_type==='image' && data['state'] === 'processed'){
                        setRecomms({
                            "data": {
                                "general_assessment": data['data']["general_assessment"],
                                "general_recommendation": "Not supported for images",
                                "per_scene_recommendation": [],
                            },
                            "state": "processed"
                        });
                    }
                    if (data['state'] === 'not_requested'){
                        requestPredictions(id, file_type).then((data)=> {
                            setPredictions(data);
                            setTimeout(()=>{
                                pred_check(file_type);
                            },1000);
                        });
                    } else if (data['state'] !== 'failed' && data['state'] !== 'processed'){
                        setTimeout(()=>{
                            pred_check(file_type);
                        },5000);
                    } else if (data['state'] === 'failed') {
                        setErrors(prev => [...prev, data['error']]);
                        loggingService.log(`Predictions check failed with ${id} - ${data['$serverError']}`);
                    }
                    else{
                        loggingService.log(`Predictions check finished with ${id} - ${performance.now()-startTime} ms`);
                    }
                })
            }
            loggingService.log(`Predictions check started with ${id}`);
            pred_check(info['file_type']);
            loggingService.log(`Heatmap check started with ${id}`);
            setTimeout(heatmap_check, 500);
            // fixme: fogmap feature is switched off till api is fixed
            // loggingService.log(`Fogmap check started with ${id}`);
            // setTimeout(fogmap_check, 500);
            loggingService.log(`Recommendation check started with ${id}`);
            setTimeout(recommendations_check, 500);
            setRecommendcationsTriggered();
        }
    }, [info])


    function setActivePlayer(isOriginal) {
        if (mainPlayer && heatmapPlaeyr){
            if (isOriginal){
                mainPlayer.currentTime = heatmapPlaeyr.currentTime;
                if(!heatmapPlaeyr.paused) {
                    heatmapPlaeyr.pause();
                }
                if (mainPlayer.paused && !heatmapPlaeyr.paused) {
                    mainPlayer.play();
                }
                // syncPlayers({'currentTarget':mainPlayer});
            }else {
                heatmapPlaeyr.currentTime = mainPlayer.currentTime;
                if(heatmapPlaeyr.paused && !mainPlayer.paused) {
                    heatmapPlaeyr.play();
                }
                // syncPlayers({'currentTarget':heatmapPlaeyr});
            }
        }



        setActiveVideo(isOriginal? 'Original':'Heatmap')

    }

    function showProgress(info) {
        return info.split(':').pop();
    }

    return (
        <div className="container" style={{ width: "800px" }}>
                <div className='uploading-progress-form'  style={{
                    display: predictions['state'] !== 'processed' && recomms['state'] !== 'processed' ? 'flex': 'none'
                }}>
                    <div className='uploading-icon'>
                        {/*<BsArrowRepeat className='icon' />*/}
                    </div>
                    <p className='caption'>Analyzing media file</p>
                    <p>
                        (This usually takes a minute or so)
                        <br/>
                        {predictions['info']?<span>{'Progress:' + showProgress(predictions['info'])}</span>: null}
                    </p>
                </div>
                {errors.length > 0 ? <div className='error'>
                    <ul>
                        {errors.map((e, i) => (
                            <li key={i}>{e}</li>
                        ))}
                    </ul>
                    <div>
                    <p onClick={() => navigate("/upload")} style={{paddingLeft: '5px', fontWeight:'700', textDecoration: 'underline', cursor: 'pointer'}}>TRY A DIFFERENT FILE</p>
                    </div>
                </div> : null}
                <div className='results-form'  style={{
                    display: predictions['state'] ==='processed' || recomms['state'] ==='processed' ? 'block': 'none'
                }}>
                    <div style={{display:((activeVideo==='Original' && !isFogmapActive)?'block':'none'), textAlign:'center'}}>
                        {info['file_type'] === 'video'?
                            <VideoPlayer id='main' info={info} no_title={true} onTimeUpdate={syncPlayers}>
                            </VideoPlayer>:
                            <img className='player-row-img' src={info['url']}/>
                        }
                    </div>
                   
                    {info['file_type'] === 'video'
                        ?   (
                                <div style={{display:((activeVideo==='Heatmap' || isFogmapActive) ?'block':'none')}}>
                                    <VideoPlayer id='heatmap' info={isFogmapActive ? {"url":fogmap['data']}  : heatmap? {"url":heatmap['data']}:info} no_title={true} onTimeUpdate={syncPlayers}>
                                    </VideoPlayer>
                                </div>
                            )
                        :   (
                                <div style={{display:((activeVideo==='Heatmap' || isFogmapActive) ?'block':'none'), textAlign:'center'}}>
                                    <img className='player-row-img' src={isFogmapActive ? fogmap['data'] : heatmap['data']}/>
                                </div>
                            )
                    }

                    <div style={{position: "relative"}}>
                    
                    <div>
                    <Form.Check style={{ display: heatmap['state'] === 'unavailable' ? 'none': 'block'}}
                        type="switch"
                        variant="success"
                        disabled={heatmap['state'] !== 'processed'}
                        id="active-video"
                        label={heatmap['state'] !== 'processed'? 'Attention Heatmap (processing...)': 'Attention Heatmap'}
                        checked={activeVideo !== 'Original'}
                        onChange={(e) => setActivePlayer(!e.currentTarget.checked)}
                    />
                    
                    {/* fixme: fogmap feature is switched off till api is not fixed*/}
                    {/*<Form.Check style={{ display: fogmap['state'] === 'unavailable' ? 'none': 'block'}}*/}
                    {/*    type="switch"*/}
                    {/*    variant="success"*/}
                    {/*    disabled={fogmap['state'] !== 'processed'}*/}
                    {/*    id="active-video"*/}
                    {/*    label={fogmap['state'] !== 'processed'? 'Attention Fogmap (processing...)': 'Attention Fogmap'}*/}
                    {/*    checked={isFogmapActive}*/}
                    {/*    onChange={(e) => setIsFogmapActive(!isFogmapActive)}*/}
                    {/*/>*/}

                    </div>                    
                        <div style={{position: "absolute", top: 6, right: 25}}>
                            <Button className="pv-button" style={{padding: "0.75em 25px"}} variant="secondary" onClick={onShareClick}>
                                <BsFillShareFill style={{marginRight: "5px", marginTop: "-3px" }} /> Share results
                            </Button>
                            <ToastContainer position="abosulute" style={{bottom: "-50px"}}>
                            <Toast show={showCopiedToClipboard} autohide={true} delay={2500} onClose={() => setShowCopiedToClipboard(false)}>          
                                <Toast.Body><div style={{textAlign: "center"}}>Link copied to Clipboard</div></Toast.Body>
                            </Toast>
                        </ToastContainer>
                        </div>
                    </div>

                    <RecommendationsRow recomms={recomms} predictions={predictions} v_info={info}>
                    </RecommendationsRow>
                </div>
            </div>
        )
}

export default RecommendationsResults;
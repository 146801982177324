import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LoggingService } from "./services/LoggingService";


const root = ReactDOM.createRoot(document.querySelector('body'));
let loggingService = new LoggingService(process.env.NODE_ENV === "development");
loggingService.init();

root.render(
  <React.StrictMode>
    <App loggingService={loggingService} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";

export class LoggingService {
  constructor(devMode = false) {
    this.loggingSessionId = uuidv4();
    this.initialized = false;
    this.initializing = false;
    this.cloudWatchClient = null;
    this.logStreamName = null;
    this.eventsQueue = [];
    this.devMode = devMode;
  }

  async init() {
    if (this.initialized || this.initializing) {
      return;
    }
    try {
      this.initializing = true;

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_PREVIEW_SITE_COGNITO,
      });
      AWS.config.region = "eu-west-1";

      const cloudWatchClient = new AWS.CloudWatchLogs({
        credentials: AWS.config.credentials,
        region: "eu-west-1",
      });

      const currentDate = new Date();
      const formattedDate = currentDate
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "/");
      const logStreamName = `${formattedDate}[$LATEST]${this.loggingSessionId}`;

      await cloudWatchClient
        .createLogStream({
          logGroupName: process.env.REACT_APP_PREVIEW_SITE_LOG_GROUP,
          logStreamName: logStreamName,
        })
        .promise();

      this.logStreamName = logStreamName;
      this.cloudWatchClient = cloudWatchClient;
      this.initialized = true;
      if (this.eventsQueue.length > 0) {
        await this._logInternal(this.eventsQueue);
        this.eventsQueue = [];
      }
    } catch (error) {
      console.error("Error initializing logging service", error);
    }
  }

  async log(message) {
    const event = {
      message,
      timestamp: new Date().getTime(),
    };
    if (this.devMode) console.log(message);
    if (!this.initialized) {
      this.eventsQueue.push(event);
      return;
    }
    this._logInternal([event]);
  }

  async _logInternal(events) {
    try {
      await this.cloudWatchClient
        .putLogEvents({
          logGroupName: process.env.REACT_APP_PREVIEW_SITE_LOG_GROUP,
          logStreamName: this.logStreamName,
          logEvents: events,
        })
        .promise();
    } catch (error) {
      console.error("Error logging to CloudWatch", error);
    }
  }
}
